<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2 merchant-register-card">
      <b-card title="NEW MERCHANT REGISTRATION">
        <b-overlay
          :show="modalLoading"
          blur="10px"
          opacity="0.30"
          rounded="sm"
          variant="light"
        >
          <ValidationObserver
            ref="createMerchantForm"
            v-slot="{ handleSubmit }"
            slim
          >
            <b-form
              class="my-8"
              @submit.prevent="handleSubmit(onClickCreateMerchant)"
            >
              <!--           Merchant Primary Business Details-->
              <b-row>
                <b-col
                  cols="12"
                  class="d-flex justify-content-center"
                >
                  <div class="d-flex mt-1 mb-1">
                    <feather-icon
                      icon="BriefcaseIcon"
                      size="19"
                    />
                    <h4 class="mb-0 ml-0 ml-md-50 sub-heading">
                      Merchant Primary Business Details
                    </h4>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Name"
                    label-for="h-name"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <b-form-input
                        id="name"
                        v-model="form.name"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter the merchant name"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Address Line 1"
                    label-for="h-addressLine1"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="addressLine1"
                      rules="required"
                    >
                      <b-form-input
                        id="addressLine1"
                        v-model="form.addressLine1"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter the address line 1"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Address Line 2"
                    label-for="h-addressLine2"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="addressLine2"
                      rules=""
                    >
                      <b-form-input
                        id="addressLine2"
                        v-model="form.addressLine2"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter the address line 2"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Address Line 3"
                    label-for="h-addressLine3"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="addressLine3"
                      rules=""
                    >
                      <b-form-input
                        id="addressLine3"
                        v-model="form.addressLine3"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter the address line 3"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Postal /Zip Code"
                    label-for="h-postalCode"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="postalCode"
                      rules="required"
                    >
                      <b-form-input
                        id="postalCode"
                        v-model="form.postalCode"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter the postal /zip Code"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Country"
                    label-for="h-country"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="country"
                      rules="required"
                    >
                      <v-select
                        v-model="form.country"
                        :options="countryOptions"
                        :reduce="option => option.id"
                        label="name"
                        placeholder="Select the country"
                        autocomplete="nope"
                        @input="fetchStateList()"
                      >
                        <template v-slot:option="option">
                          {{ option.name }}
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="State"
                    label-for="h-state"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="state"
                      rules="required"
                    >
                      <v-select
                        v-model="form.state"
                        :options="stateOptions"
                        :reduce="option => option.id"
                        label="name"
                        placeholder="Select the state"
                        autocomplete="nope"
                        @input="fetchCityList()"
                      >
                        <template v-slot:option="option">
                          {{ option.name }}
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="City"
                    label-for="h-city"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="city"
                      rules="required"
                    >
                      <v-select
                        v-model="form.city"
                        :options="cityOptions"
                        :reduce="option => option.id"
                        label="name"
                        placeholder="Select the city"
                        autocomplete="nope"
                      >
                        <template v-slot:option="option">
                          {{ option.name }}
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Contact No."
                    label-for="h-contactNo"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="contactNo"
                      rules="required"
                    >
                      <b-form-input
                        id="contactNo"
                        v-model="form.contactNo"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter the contact no."
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Email"
                    label-for="h-email"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="email"
                      rules="required"
                    >
                      <b-form-input
                        id="email"
                        v-model="form.email"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter the email"
                        type="email"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
              <!--           Merchant Admin Details-->
              <b-row>
                <b-col cols="4" />
                <b-col
                  cols="12"
                  class="d-flex justify-content-center"
                >
                  <div class="d-flex mt-1 mb-1">
                    <feather-icon
                      icon="UserIcon"
                      size="19"
                    />
                    <h4 class="mb-0 ml-0 ml-md-50 sub-heading">
                      Merchant Admin Details
                    </h4>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Username"
                    label-for="h-username"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="username"
                      rules="required"
                    >
                      <b-form-input
                        id="username"
                        v-model="form.username"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter the username"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Password"
                    label-for="h-password"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="password"
                      rules="required"
                    >
                      <b-form-input
                        id="password"
                        v-model="form.password"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter the password"
                        type="password"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="First Name"
                    label-for="h-firstName"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="firstName"
                      rules="required"
                    >
                      <b-form-input
                        id="firstName"
                        v-model="form.firstName"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter the first name"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Last Name"
                    label-for="h-lastName"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="lastName"
                      rules="required"
                    >
                      <b-form-input
                        id="lastName"
                        v-model="form.lastName"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter the last name"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Email"
                    label-for="h-userEmail"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="userEmail"
                      rules="required"
                    >
                      <b-form-input
                        id="userEmail"
                        v-model="form.userEmail"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter the admin email"
                        type="email"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Contact No."
                    label-for="h-userContactNo"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="userContactNo"
                      rules="required"
                    >
                      <b-form-input
                        id="userContactNo"
                        v-model="form.userContactNo"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter the contactNo"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
              <!--           Bank Details-->
              <b-row>
                <b-col cols="4" />
                <b-col
                  cols="12"
                  class="d-flex justify-content-center"
                >
                  <div class="d-flex mt-1 mb-1">
                    <feather-icon
                      icon="DollarSignIcon"
                      size="19"
                    />
                    <h4 class="mb-0 ml-0 ml-md-50 sub-heading">
                      Bank Details
                    </h4>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Account Name"
                    label-for="h-accountName"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="accountName"
                      rules="required"
                    >
                      <b-form-input
                        id="accountName"
                        v-model="form.accountName"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter the account name"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Account No."
                    label-for="h-accountNo"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="accountNo"
                      rules="required"
                    >
                      <b-form-input
                        id="accountNo"
                        v-model="form.accountNo"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter the account no"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Bank Name"
                    label-for="h-bankName"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="bankName"
                      rules="required"
                    >
                      <b-form-input
                        id="bankName"
                        v-model="form.bankName"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter the bank name"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Branch Name"
                    label-for="h-branchName"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="branchName"
                      rules="required"
                    >
                      <b-form-input
                        id="branchName"
                        v-model="form.branchName"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter the branch name"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Branch Code"
                    label-for="h-branchCode"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="branchCode"
                    >
                      <b-form-input
                        id="branchCode"
                        v-model="form.branchCode"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter the branch code"
                        type="text"
                        autocomplete="nope"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="d-flex justify-content-center align-items-center">
                <b-card
                  v-if="merchantType"
                  title="MERCHANT TYPE"
                >
                  <b-row>
                    <!-- Radio Selection -->
                    <b-col cols="12">
                      <b-form-radio-group
                        v-model="merchantTypeSelected"
                        :options="merchantTypeOptions"
                        class="demo-inline-spacing mb-1"
                        value-field="value"
                        text-field="text"
                      />
                    </b-col>
                  </b-row>
                </b-card>
              </b-row>
              <!-- submit and reset -->
              <b-row>
                <b-col
                  cols="12"
                  class="d-flex justify-content-center"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                  >
                    Submit
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="reset"
                    variant="outline-secondary"
                  >
                    Clear
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </b-overlay>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BFormRadioGroup,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  // BFormCheckbox,
  BRow,
  BOverlay,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import vSelect from 'vue-select'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

// const ResourceRepository = RepositoryFactory.get('resource')
// const MerchantRepository = RepositoryFactory.get('merchant')
const MerchantSelfRepository = RepositoryFactory.get('SelfRegister')
const MerchantRepository = RepositoryFactory.get('merchantSetting')

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BFormRadioGroup,
    BButton,
    BCard,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    // BFormCheckbox,
    BRow,
    BOverlay,
    'v-select': vSelect,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      merchantType: null,
      open: false,
      id: null,
      form: {},
      modalLoading: false,
      loading: false,
      cityOptions: [],
      stateOptions: [],
      countryOptions: [],
      merchantTypeSelected: 'credit',
      merchantTypeOptions: [
        { text: 'Cash', value: 'cash', disabled: false },
        { text: 'Credit', value: 'credit', disabled: false },
      ],
    }
  },
  mounted() {
    this.open = true
    this.modalLoading = true
    this.fetchCountryList()
    this.fetchStateList()
    this.fetchCityList()
    this.getMerchantSetting()
    this.modalLoading = false
  },
  methods: {
    async fetchCityList() {
      this.form.city = null
      try {
        this.loading = true
        if (this.form.state === null || this.form.state === undefined || this.form.state === '') {
          this.cityOptions = []
        } else {
          const { data } = (await MerchantSelfRepository.getCityListByID(this.form.state)).data
          this.cityOptions = data
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async fetchStateList() {
      this.form.state = null
      this.form.city = null
      try {
        if (this.form.country !== undefined) {
          const { data } = (await MerchantSelfRepository.getStateListByID(this.form.country)).data
          this.stateOptions = data
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async fetchCountryList() {
      try {
        const { data } = (await MerchantSelfRepository.getCountryList()).data
        this.countryOptions = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },

    async onClickCreateMerchant() {
      this.modalLoading = true
      try {
        const payload = {
          name: this.form.name,
          business_name: this.form.name,
          bank_details: {
            account_name: this.form.accountName,
            account_number: this.form.accountNo,
            bank_name: this.form.bankName,
            branch_name: this.form.branchName,
            branch_code: this.form.branchCode,
          },
          user_details: {
            first_name: this.form.firstName,
            last_name: this.form.lastName,
            email: this.form.userEmail,
            user_name: this.form.username,
            phone: this.form.userContactNo,
            password: this.form.password,
          },
          address_details: {
            address_line_1: this.form.addressLine1,
            address_line_2: this.form.addressLine2,
            address_line_3: this.form.addressLine3,
            city_id: this.form.city,
            state_id: this.form.state,
            phone: this.form.contactNo,
            country_id: this.form.country,
            postal_code: this.form.postalCode,
            type: 'general',
            is_default: true,
          },
          contact_details: {
            phone: this.form.contactNo,
            email: this.form.email,
          },
          business_setting: {
            merchant_type: this.merchantTypeSelected,
          },
        }

        await MerchantSelfRepository.setMerchant(payload)
        this.showSuccessMessage('Your account has been created successfully, please contact your courier service provider to activate your account')
        await this.$router.push('/login')
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
    async getMerchantSetting() {
      try {
        const { data } = (await MerchantRepository.getMerchantSettingsResource()).data
        this.merchantType = data.merchant_type_select
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
  },
}
</script>
<style scoped>
@import "../../../node_modules/@syncfusion/ej2-base/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/fabric.css";
</style>
<style lang="scss" scoped>
@import '@core/scss/base/pages/page-auth.scss';
.card-title {
  display: flex;
  justify-content: center;
  font-size: 1.5rem !important;
  text-align: center;
}
.sub-heading {
  text-align: center;
}
</style>
